@import "../../../../index.css";

.section-clinic-detail {
  .inner-container {
    display: flex;

    .left-column {
      display: flex;
      flex-direction: column;
      flex: 0.9;

      .greeting-message {
        width: 100%;
        padding: 65px;
        color: #0a54a2;
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 52px;
        display: flex;

        span {
          margin: auto;
        }
      }
    }

    .right-column {
      background: #e6e8e9;
      flex: 1;
      position: relative;

      .container-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: min(596px, 100%);
        height: 370.58px;
        gap: 24px;
        padding: 10px;

        .container-wrapper-inner {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        progress {
          width: 100%;
          height: 18px;
        }

        .title {
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: rgba(18, 31, 62, 0.8);
        }

        .sub-title {
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: rgba(18, 31, 62, 0.8);
        }

        .input-container {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .select-container {
            position: relative;
            .form-select {
              background: #f5f5f5;
              height: 100%;
            }
            .form-select:focus {
              box-shadow: none;
              transition: all 0.1s linear;
              border-color: #3b71ca;
              box-shadow: inset 0px 0px 0px 1px #3b71ca;
            }

            label {
              position: absolute;
              top: 0;
              color: rgba(18, 31, 62, 0.8);
              left: 0.75rem;
              pointer-events: none;
              transform-origin: 0 0;
              padding-top: 0.33rem;
              transform: translateY(-0.85rem) translateY(0.1rem) scale(0.8);
              z-index: 1;
            }
            .form-notch {
              display: flex;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              max-width: 100%;
              height: 100%;
              text-align: left;
              pointer-events: none;
              .form-notch-leading {
                left: 0;
                top: 0;
                height: 100%;
                width: 0.5rem;
                border: none;
              }
              div {
                pointer-events: none;
                border: 1px solid;
                border-color: #bdbdbd;
                box-sizing: border-box;
                background: transparent;
                transition: all 0.2s linear;
              }

              .form-notch-middle-state {
                width: 41px;
                flex: 0 0 auto;
                // width: auto;
                max-width: calc(100% - 1rem);
                height: 100%;
                border-right: none;
                border-left: none;
              }
              .form-notch-middle-city {
                width: 32px;
                flex: 0 0 auto;
                // width: auto;
                max-width: calc(100% - 1rem);
                height: 100%;
                border-right: none;
                border-left: none;
              }
              div {
                pointer-events: none;
                border: 2px solid;
                border-color: #e6e8e9;
                border-bottom: transparent;
                box-sizing: border-box;
                background: transparent;
                transition: all 0.2s linear;
              }
            }
          }

          .input-box {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
          }
          .form-label {
            font-weight: 400;
            font-size: 16px;
            display: flex;
            margin: auto;
            color: rgba(18, 31, 62, 0.8);
          }

          input {
            background: #f5f5f5;
            border: 1px solid #dfe3e6;
            border-radius: 4px;
            height: 40px;
            width: 100%;
            padding-left: 16px;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
          }

          textarea {
            background: #f5f5f5;
            border: 1px solid #dfe3e6;
            border-radius: 4px;
            height: 91px;
            width: 100%;
            padding-left: 16px;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
          }

          .pincode-state-city {
            display: grid;
            grid: 40px / auto auto auto;
            gap: 13px;
          }
        }

        .button-container {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;

          .next {
            width: 79px;
            height: 40px;
            border-radius: 4px;
          }

          .prev {
            width: 108px;
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

/* ************************************************** media query **************************************************  */

@media (max-width: 63em) {
  .section-clinic-detail {
    .inner-container {
      .left-column {
        display: none;
      }

      .right-column {
        position: unset;

        .container-wrapper {
          padding: 0 45px;
          position: unset;
          transform: unset;
          height: 560px;
          margin: auto;

          .container-wrapper-inner {
            margin: auto;
          }

          .title {
            padding: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 33px;
          }

          .sub-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 33px;
          }
        }
      }
    }
  }
}
