.outer-row-dash {
    background-color: #eaebf0;
    border-radius: 10px;
    border: 1px solid #bebfc1;
    input:disabled,
    textarea:disabled {
        cursor: not-allowed;
        color: #00000090 !important;
    }
    .first-div {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .edit-profile {
        padding: 12px 24px;
        color: #272d37;
        /* Medium : 24px | Line height : 32px */
        font-size: 24px;
        // font-family: Montserrat;
        font-weight: 500;
        line-height: 32px;
        // width: 100%;
    }
    .Edit-btn {
        color: #0290de;
        cursor: pointer;
        font-size: 20px;
        // font-family: Montserrat;
        font-weight: 500;
        line-height: 30px;
    }
    .row-dash {
        display: flex;
        padding: 24px;
        align-items: flex-start;
        gap: 60px;
        align-self: stretch;
        background-color: #fff;
        margin-bottom: 6.25rem;
        .col-1-dash {
            // width: 100%;
            input {
                display: none;
                /* hide the input element */
            }
            height: 200px;
            width: 200px;
            .img-tag {
                width: 100%;
                height: 100%;
                flex-shrink: 0;
                border: 1px solid #eee;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: #eee;
                color: #000;
                text-align: center;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 700;
                line-height: 20px;
                text-transform: uppercase;
                padding: 10px;
                object-fit: cover;
            }
        }
        .col-2-dash {
            width: 90%;
            // .personal-info {
            //     color: #000;
            //     font-size: 20px;
            //     // font-family: Montserrat;
            //     font-weight: 600;
            //     line-height: 30px;
            //     text-transform: capitalize;
            // }
            h3 {
                color: #000;
                /* Semibold : 20px | Line height : 30px */
                font-size: 20px;
                font-family: Montserrat;
                font-weight: 600;
                line-height: 30px;
                span {
                    color: #0290de;
                    font-size: 16px;
                    margin-left: 16px;
                    cursor: pointer;
                }
            }
            .form {
                margin: 0 auto;
                .div-add {
                    width: 100% !important;
                    margin-top: 10px;
                }
                .h-76 {
                    height: 76px !important;
                }
                .select-inp {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    background-color: #f5f5f5;
                    color: #000;
                    /* Regular : 16px | Line height : 24px */
                    font-size: 16px;
                    // font-family: Montserrat;
                    line-height: 24px;
                }
                .input-wrap {
                    display: flex;
                    min-width: 300px;
                    max-width: 500px;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    background-color: #f5f5f5;
                    gap: 10px;
                    img {
                        margin: auto 0;
                    }
                    align-items: center;
                }
                .inp-2 {
                    padding: 8px;
                    border: 1px solid #ccc;
                    max-width: 240px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    background-color: #f5f5f5;
                    color: #000;
                    /* Regular : 16px | Line height : 24px */
                    font-size: 16px;
                    // font-family: Montserrat;
                    line-height: 24px;
                    height: 36px;
                    margin-top: 10px;
                }
                .no-style {
                    width: 100% !important;
                    padding: 0 !important;
                    border: none !important;
                    margin-bottom: 0 !important;
                    &:focus {
                        outline: none;
                    }
                }
                .txt-inp {
                    width: 100%;
                    min-width: 300px;
                    max-width: 500px;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    background-color: #f5f5f5;
                    color: #000;
                    /* Regular : 16px | Line height : 24px */
                    font-size: 16px;
                    // font-family: Montserrat;
                    line-height: 24px;
                    height: 38px;
                }
                // .personal-info {
                //     font-size: 18px;
                //     font-weight: bold;
                //     margin-bottom: 10px;
                // }
                .time {
                    align-items: center;
                }
                .display-flex {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    div {
                        display: flex;
                        flex-direction: column;
                        label {
                            display: block;
                            margin-bottom: 5px;
                            color: #000;
                            font-size: 16px;
                            font-family: Montserrat;
                            font-weight: 500;
                            line-height: 24px;
                            text-transform: capitalize;
                        }
                        input {
                            width: 100%;
                            min-width: 300px;
                            //   max-width: 300px;
                            padding: 8px;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            // margin-bottom: 10px;
                            background-color: #f5f5f5;
                            color: #000;
                            /* Regular : 16px | Line height : 24px */
                            font-size: 16px;
                            // font-family: Montserrat;
                            line-height: 24px;
                        }
                    }
                }
                .custome-slot {
                    margin-top: 10px;
                }
                .line {
                    border: 2px solid #bebfc1;
                    width: 100%;
                }
                .div-001 {
                    form {
                        display: flex;
                        flex-direction: column;
                    }
                    h3 {
                        color: #000;
                        /* Semibold : 20px | Line height : 30px */
                        font-size: 20px;
                        font-family: Montserrat;
                        font-weight: 600;
                        line-height: 30px;
                        span {
                            color: #0290de;
                            font-size: 16px;
                            margin-left: 16px;
                            cursor: pointer;
                        }
                    }
                    label {
                        display: block;
                        margin-bottom: 5px;
                        color: #000;
                        font-size: 16px;
                        font-family: Montserrat;
                        font-weight: 500;
                        line-height: 24px;
                        text-transform: capitalize;
                    }
                    input,
                    select {
                        width: 100%;
                        max-width: 500px;
                        min-width: 300px;
                        padding: 8px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        background-color: #f5f5f5;
                        color: #000;
                        /* Regular : 16px | Line height : 24px */
                        font-size: 16px;
                        font-family: Montserrat;
                        line-height: 24px;
                    }
                    textarea {
                        width: 100%;
                        max-width: 500px;
                        padding: 8px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        background-color: #f5f5f5;
                        color: #000;
                        /* Regular : 16px | Line height : 24px */
                        font-size: 16px;
                        font-family: Montserrat;
                        line-height: 24px;
                    }
                    .searchWrapper {
                        border: none;
                        border-radius: none;
                        padding: 0;
                        min-height: unset;
                        input {
                            width: 100%;
                        }
                    }
                }
                .save {
                    margin-top: 10px;
                    display: flex;
                    padding: 10px 24px;
                    align-items: center;
                    align-self: flex-start;
                    gap: 8px;
                    border-radius: 4px;
                    background-color: #d6e7ef;
                    border: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .row-dash {
        flex-direction: column;
        .col-1-dash {
            width: 100%;
        }
        .col-2-dash {
            width: 100%;
            .form {
                input {
                    max-width: 500px;
                    width: 100% !important;
                }
                select {
                    max-width: 500px;
                    width: 100% !important;
                }
                textarea {
                    max-width: 500px;
                    width: 100% !important;
                }
            }
        }
    }
    .time {
        align-items: flex-start !important;
    }
    .display-flex {
        flex-direction: column;
        gap: 0 !important;
    }
    .inp-1,
    .inp-2 {
        max-width: 500px;
        width: 100% !important;
    }
    .txt-inp {
        max-width: 500px;
        width: 100% !important;
    }
    .div-001 {
        input {
            max-width: 500px;
            width: 100% !important;
        }
    }
    .input-wrap {
        max-width: 500px;
        min-width: 0 !important;
    }
}

@media only screen and (max-width: 400px) {
    .outer-row-dash {
        .row-dash {
            flex-direction: column;
            padding: 16px;
        }
    }
    input {
        min-width: 100px !important;
    }
}