.chat-bot {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.chat-bot::-webkit-scrollbar {
  width: 10px;
}

.chat-bot::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.chat-bot::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 5px;
}
.chat-image{
  width: 100px !important;
  height: 100px !important;
  border-radius: 4px;
}
.chat-bot::-webkit-scrollbar-thumb:hover {
  background-color: #717171;
}

.chat-bot::-webkit-scrollbar-thumb {
  width: 5px;
  /* Set your desired width */
  background-color: #ababab;
  border-radius: 5px;
}

/* Adjust the width of the scrollbar thumb on hover */

.chat-messages::-webkit-scrollbar-thumb:hover {
  width: 10px;
  /* Set your desired width */
  background-color: #8d8d8d;
}

// .GentleDentleBot {
//     .bot-img {
//         img {
//             width: 20px !important;
//         }
//     }
// }
.chat-bot {
  background-color: #fff;
  width: 100%;
  padding: 8px;
  position: relative;
  // padding-bottom: 100px;
  overflow-y: scroll;
  height: 575px;
  /* Add this code within the .chat-bot selector */
  /* Scrollbar styling */
  .chat-messages {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    padding-right: 10px;

    gap: 15px;
    // height: 90%;
    // overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    position: relative;
    .time-name {
      display: flex;
      margin-top: 10px;
      &.bot {
        align-self: flex-start;
      }
      &.user {
        display: none;
        align-self: flex-end;
      }
      .bot-img img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
      .timestamp {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: auto;
        margin-left: 6px;
        margin-bottom: auto;
      }
      .sender {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-transform: capitalize;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 6px;
      }
    }
    .file-message {
      width: 100%;
    }
    .message {
      margin-bottom: 10px;
      max-width: 400px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      &.GentleDentleBot {
        align-self: flex-start;
        background-color: #f2f2f2;
        border: 1px solid #dddee2;
        border-radius: 5px;
        padding: 10px;
      }
      &.user {
        display: none;
        align-self: flex-end;
        background-color: #dcf8c6;
        border-radius: 5px;
        padding: 10px;
      }
      .sender {
        font-weight: bold;
        margin-right: 5px;
      }
      .text {
        font-size: 14px;
        // margin-top: 5px;
      }
      .user-text {
        padding: 4px 10px;
      }
    }
  }
  .self {
    align-self: flex-end !important;
  }
  .message.self {
    background-color: #0290de !important;
    border: 1px solid #0290de !important;
    color: #ffffff;
  }
  .image-upload-container {
    display: flex;
    margin-top: 20px;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    &:hover {
      background-color: #0056b3;
    }
  }
  input[type="file"] {
    display: none;
  }
  .button-container {
    display: flex;
    margin-top: 10px;
    .bkc-blue {
      background-color: #007bff;
      color: #fff;
    }
    button {
      margin: 0 5px;
      padding: 8px 12px;
      background-color: #fff;
      color: #007bff;
      border: none;
      border-radius: 5px;
      border: 1.5px solid #007bff;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #007bff;
        color: #fff;
      }
    }
  }
  .appointment {
    background-color: #fff;
  }
}

.input-div {
  padding: 10px;
  background-color: #fff;
}

.input-block {
  border-radius: 4px;
  display: flex;
  width: 100%;
  padding: 10px;
  // background-color: #f5f5f5;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  input {
    width: 100%;
    padding: 8px;
    outline: none;
    border: 0px;
    background-color: #f5f5f5;
  }
  .file-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 0px 0px;
    cursor: pointer;
    input {
      display: none;
    }
    svg {
      path {
        width: 24px;
        height: 24px;
      }
      fill: #787878;
    }
  }
  .send-msg {
    margin: auto;
    cursor: pointer;
  }
}

.user-self {
  width: fit-content;
  // align-self: flex-end !important;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-end;
}

.align-flex-start {
  align-self: flex-start !important;
  align-items: flex-start;
  .user-self-msg {
    background-color: #f2f2f2 !important;
    border: 1px solid #dddee2 !important;
    color: #000 !important;
  }
}

.user-self-msg {
  background-color: #0290de !important;
  border: 1px solid #0290de !important;
  color: #ffffff;
  border-radius: 5px;
  max-width: 450px;
}
