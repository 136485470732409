.email-verify-wrapper{
    margin: 60px 30px;
    height: 50vh;

}
.email-verify-error{
    text-align: center;
    background-color: #fff;
    margin: 60px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: min(500px,100%);
    .header{
        color: red;
    }
}
.email-verify {
    text-align: center;
    background-color: #fff;
    margin: 60px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: min(500px,100%);
}
.header {
    color: #4CAF50; /* Green */
    margin-bottom: 40px;
}
p {
    font-size: 18px;
}
.footer {
    margin-top: 40px;
    color: #888;
    font-size: 12px;
}