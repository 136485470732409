@import "../../index.css";
.section-header {
    .comman-container {
        .web-inner-container {
            background: #fff;
            display: flex;
            padding: 15px 165px;
            gap: 108px;
            .logo-link {
                display: flex;
                .logo {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    color: #0290DE;
                    margin: auto;
                    display: flex;
                    small {
                        color: #000000;
                        font-size: 14px;
                    }
                    img{
                        width: 220px;
                    }
                }
            }
            .link-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .nav-link-wrapper {
                    display: flex;
                    gap: 24px;
                    .nav-link {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #000000;
                        cursor: pointer;
                    }
                }
                .user {
                    .dashboard {
                        width: auto !important;
                        padding: 0 15px;
                    }
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                    .login,
                    .signup,
                    .logout {
                        width: 85px;
                        height: 32px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        border-radius: 4px;
                    }
                    .user-detail {
                        display: flex;
                        gap: 6px;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        .mobile-inner-container {
            padding: 16px;
            display: none;
            justify-content: space-between;
            .logo {
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                color: #0290DE;
                small {
                    color: #000000;
                    font-size: 12px;
                }
                img{
                    width: 200px;
                }
            }
        }
    }
}


/* ************************************************** media query **************************************************  */

@media (max-width: 1100px) {
    .section-header {
        .comman-container {
            .web-inner-container {
                background: #F8F8F8;
                display: flex;
                padding: 15px 92px;
                gap: 108px;
            }
        }
    }
}

@media (max-width: 1100px) {
    .web-inner-container {
        display: none !important;
    }
    .mobile-inner-container {
        display: flex !important;
    }
}

// **************************************************** sidebar style *****************************************
.offcanvas {
    width: auto !important;
    .offcanvas-body {
        .nav-link-wrapper {
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding-left: 12px;
            a {
                color: #000000;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                display: flex;
                align-items: center;
                gap: 8px;
                p {
                    margin: 0;
                }
            }
            .action-btn {
                margin: auto;
                button {
                    width: 150px;
                    padding: 0px 10px;
                    border-radius: 4px;
                }
            }
            .logout {
                width: 85px;
                height: 32px;
                border-radius: 4px;
            }
            .line {
                width: 43px;
                height: 0px;
                border: 2px solid #0290DE;
                background-color: #0290DE;
            }
            .social-link {
                display: flex;
                gap: 16px;
                img {
                    height: 24px;
                    width: 24px;
                }
            }
            .toll-free-number {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: #3A3A3A;
            }
        }
    }
}