@import "../../index.css";

.section-dashboard-home {
    .comman-container {
        .inner-container {
            display: flex;

            .column-1 {
                width: 425px;
                // height: 90vh;
                overflow-y: auto;
                background: #FFFFFF;
                padding: 24px;
                border-right: 1px solid #EBEBEB;

                .check-your-teeth {
                    width: 188px;
                    height: 44px;
                    background: #0290DE;
                    border-radius: 4px;
                    border: none;
                    color: #FFFFFF;
                    margin-bottom: 26px;
                }

                .profile {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 35px;

                    .user-detail {
                        display: flex;
                        gap: 12px;
                        align-items: center;

                        .username {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: #0D0E14;
                        }

                        .edite {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: #0290DE;
                        }

                        .profile-pic {
                            width: 50px;
                            height: 50px;
                        }

                    }

                    .social-link {
                        display: flex;
                        gap: 12px;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .dashboard-link {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    margin-bottom: 30px;

                    .link {
                        display: flex;
                        gap: 13px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }

                }

                .under-line {
                    width: 100%;
                    height: 1px;
                    border: 1px solid #EBEBEB;
                    margin-bottom: 20px;
                }

                .share-files {
                    .heading {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        .title {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 28px;
                            color: #000000;
                        }

                        .see-all {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            color: #0290DE;
                        }
                    }

                    .files {
                        display: flex;
                        gap: 12px;
                        margin-bottom: 20px;

                        .file-logo {
                            padding: 9px;
                            background: #FFFFFF;
                        }

                        .file-name-wrapper {
                            .file-name {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                                color: #000000;
                            }

                            .file-detail {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                color: #717171;
                            }
                        }

                        .file-doenload {
                            padding: 9px;
                            background: #FFFFFF;
                        }
                    }
                }

                .dentists {
                    .heading {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 28px;
                        color: #000000;
                        margin-bottom: 20px;
                    }

                    .search-bar {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        width: 290px;
                        height: 32px;
                        margin-bottom: 32px;
                        background: #F5F5F5;
                        border: 1px solid #DFE3E6;
                        border-radius: 4px;

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        input {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: rgba(0, 0, 0, 0.3);
                            border: none;
                            background: #F5F5F5;

                        }
                    }

                    .dentist-profile-wrapper {
                        display: flex;
                        gap: 12px;
                        padding: 12px 16px;
                        border-bottom: 1px solid #EBEBEB;

                        .dentist-profile-pic {
                            width: 50px;
                            height: 50px;
                        }

                        .dentist-detail-wrapper {
                            display: flex;
                            flex-direction: column;

                            .dentist-name {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                                text-align: center;
                                color: #0D0E14;
                            }

                            .profession {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 18px;
                                margin-bottom: 4px;
                                color: rgba(0, 0, 0, 0.6);
                            }

                            .social-link-wrapper {
                                display: flex;
                                flex-direction: row;
                                gap: 10px;

                                img {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }

            .column-2 {
                width: 100%;
                position: relative;

                .loader {
                    height: 70vh;

                    svg {
                        height: 20vh;
                        width: 100%;

                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .rsc {
                    width: 100%;
                    height: 100%;

                    .rsc-container {
                        width: 100%;
                        height: 100%;

                        .rsc-header {
                            border-bottom: 1px solid #EBEBEB;


                            .lifvqk {
                                font-weight: 600;
                                font-size: 30px;
                                line-height: 38px;
                                color: #000000;
                            }
                        }

                        .rsc-footer {
                            border: 1px solid #EBEBEB;
                        }

                        .rsc-content {
                            height: 70vh;
                        }
                    }
                }
            }

        }
    }
}


/* ************************************************** media query **************************************************  */


@media (max-width: 63em) {
    .section-dashboard-home {
        .comman-container {
            .inner-container {
                display: flex;

                .column-1 {
                    display: none;
                }

                .column-2 {
                    padding: 16px;

                    .rsc {
                        width: 100%;
                        height: 100%;

                        .rsc-container {
                            width: 100%;
                            height: 100%;

                            .rsc-header {
                                border-bottom: 1px solid #EBEBEB;
                                background: #0290DE;
                                color: white;


                                .lifvqk {
                                    font-weight: 600;
                                    font-size: 30px;
                                    line-height: 38px;
                                    color: #000000;
                                }
                            }

                            .rsc-footer {
                                border: 1px solid #EBEBEB;
                            }

                            .rsc-content {
                                height: 70vh;
                            }
                        }
                    }
                }
            }
        }
    }
}