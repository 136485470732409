.file-container {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    // padding: 10px;
    // max-width: 80%;
    width: fit-content;
    // margin: 10px 0;
    padding: 4px 10px;
    cursor: pointer;
    span {
        margin: 0;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 14px;
    }
    .file-icon {
        width: 30px;
        height: 30px;
        svg {
            height: 100%;
            width: 100%;
            path {
                fill: #000000;
            }
        }
    }
    // background-color: #f2f2f2;
}

.file-container.self {
    svg path {
        fill: #958989;
    }
    background-color: #0290de;
    color: #f2f2f2;
    margin-left: auto;
    margin-right: 2px;
}

@media (max-width: 500px) {
    .file-container {
        span {
            max-width: 80%;
        }
    }
}