@import "../../index.css";

.section-signin {
  .inner-container {
    height: 899px;

    display: flex;

    .left-column {
      // width: 708px;
      display: flex;
      flex-direction: column;
      flex: 0.9;

      .greeting-message {
        width: 100%;
        padding: 65px;
        color: #0a54a2;
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 52px;
        display: flex;

        span {
          margin: auto;
        }
      }

      img {
        position: relative;
        z-index: -2;
        height: 100%;
      }
    }

    .right-column {
      background: #e6e8e9;
      flex: 1;
      position: relative;

      .container-wrapper {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: min(596px, 100%);
        height: 370.58px;
        gap: 24px;

        .container-wrapper-inner {
          width: 100%;
          padding: 0px 50px;
          gap: 24px;

          display: flex;
          flex-direction: column;
          height: fit-content;
        }

        .title {
          padding: 0px 76px;
          color: #2c3e4a;
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          text-align: center;
        }

        .sub-title {
          color: #2c3e4a;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .content {
          color: #2c3e4a;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }

        .option {
          display: flex;
          gap: 24px;
          margin: 0 auto;

          .selected {
            border: 3px solid #0290de !important;
          }

          .patient {
            width: 145.36px;
            height: 135.34px;
            background: #ffffff;
            border: 2.14822px solid #dfe3e6;
            border-radius: 4px;
            gap: 14.16px;

            img {
              margin: 34.78px auto 0px;
            }

            div {
              margin: 14.16px auto 0px;
              text-align: center;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #2c3e4a;
            }
          }

          .dentist {
            width: 145.36px;
            height: 135.34px;
            background: #ffffff;
            border: 2.14822px solid #dfe3e6;
            border-radius: 4px;
            gap: 14.16px;

            img {
              margin: 34.78px auto 0px;
            }

            div {
              margin: 14.16px auto 0px;
              text-align: center;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #2c3e4a;
            }
          }
        }

        .input-container {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .input-box {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
          }

          .form-label {
            font-weight: 400;
            font-size: 16px;
            display: flex;
            margin: auto;
            color: rgba(18, 31, 62, 0.8);
          }

          input {
            background: #f5f5f5;
            border: 1px solid #dfe3e6;
            border-radius: 4px;
            height: 40px;
            width: 100%;
            padding-left: 16px;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
          }
        }

        .forgot-password-wrap {
          display: flex;
          justify-content: space-between;

          .forgot-password {
            text-decoration: none;
            color: black;
          }
        }

        .remember-me {
          display: flex;
          flex-direction: row;

          input {
            width: auto;
            height: auto;
            margin: 0;
            margin-right: 13px;
          }
        }

        .btn-container {
          button {
            height: 40px;
            width: 100%;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .icon-container{
          display: flex;
          justify-content: center;
          .google-icon{
              display: flex;
              justify-content: center;
              align-items: center;
            border-radius: 4px;

              gap: 10px;
            font-weight: 500;

              background-color: #ffffff;
              height: 40px;
              padding: 5px;
    width: 100%;
          }
        }

        .have-account {
          display: flex;
          margin: auto;
          gap: 2px;
          color: #0c0c0c;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          font-family: "Montserrat";

          span {
            color: #0290de;
          }
        }
      }
    }
  }
}

/* ************************************************** media query **************************************************  */

@media (max-width: 1100px) {
  .section-signin {
    .inner-container {
      .left-column {
        display: none;
      }

      .right-column {
        position: unset;

        .container-wrapper {
          padding: 97px 13px;
          position: unset;
          transform: unset;
          height: unset;
          margin: auto;

          .title {
            padding: 0;
          }.container-wrapper-inner{
            padding: 0px 10px;
          }
        }
      }
    }
  }
}
