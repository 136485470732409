.appointment-detail-modal {
  width: min(390px, 100%);
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 12px;
  .heading {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }
  .profile-img {
    img {
      width: 70px;
      height: 70px;
    }
  }
  hr{
    margin: 4px 0px;
    height: 1px;
    opacity: 0.15;
  }
  .detail {
    display: flex;
    flex-direction: column;
    .title {
      color: #1f1f1f;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
    .content {
      color: #717171;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      display: flex;
      gap: 12px;
      img {
        width: 20px;
        height: 20px;
        
      }
    }
  }
}
