.cancel-appointment {
  width: min(390px, 100%);
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  gap: 16px;
  .heading {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .sub-heading{
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .text{
    color: #808080;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .sub-text{
    display: flex;
    flex-direction: column;
    color: #808080;
    text-align: center;
    strong{
      font-weight: 500;
      // color: black;

    }
  }
 
  button{
    margin: auto;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 15px;
  }
}
