.outer-side-nav {
    background-color: #fff;
    .side-nav {
        max-width: 146px;
        border-right: 1px solid #eaebf0;
        background-color: #fff;
        img {
            margin: auto;
        }
        color: black;
        .side-div,.side-div-Membership {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .side-div-Membership:hover{
            color: #0290de;
            cursor: pointer;
        }
        
        .side-div:hover {
            svg {
                path {
                    fill: #0290de
                }
            }
            color: #0290de;
            cursor: pointer;
        }
        .side-nav-txt {
            //styleName: Regular : 16px | Line height : 24px;
            // font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            padding: 10px 0;
        }
        .txt-blue {
            color: #0290de;
        }
    }
}