@import "../../index.css";
.section-dashboard-home {
  .comman-container {
    .inner-container {
      .book-appointment-wrapper {
        list-style: none;
        button {
          padding: 5px 10px;
          border-radius: 3px;
        }
      }
      display: flex;
      padding: 30px 125px;
      background-color: #f8f8f8;
      .column-2 {
        width: 100%;
        position: relative;
        height: 100%;
        .loader {
          height: 70vh;
          svg {
            height: 20vh;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .bot-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #dddee2;
          background: #fafbfc;

          button {
            margin-right: 10vw;
            padding: 5px 15px;
            border-radius: 3px;
          }

          .header-inner-wrapper {
            display: flex;
            align-items: center;
            gap: 12px;
            display: flex;
            padding: 12px 24px;

            p {
              margin: 0;
              color: #0d0e14;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
        .rsc {
          width: 100%;
          height: 90%;
          .rsc-container {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            box-shadow: none;
            .rsc-header {
              display: none;
              border-bottom: 1px solid #ebebeb;
              .lifvqk {
                font-weight: 600;
                font-size: 30px;
                line-height: 38px;
                color: #000000;
              }
            }
            .rsc-footer {
              border: 1px solid #ebebeb;
              display: flex;
              flex-direction: column-reverse;
              // gap: 20px;
              .sc-chPdSV .iUYVrA .rsc-submit-button {
                top: unset;
                transform: unset;
              }
            }
            .rsc-content {
              height: 70vh;
              padding: 0 16px;
              .rsc-ts-image {
                box-shadow: none;
                min-width: 40px;
                padding: 3px;
                // width: 37px;
                // padding: 9px;
              }
              .rsc-ts {
                margin-bottom: 8px;
                margin-top: 0;
                display: flex;
              }
              .rsc-ts-user {
                display: flex;
                justify-content: end;
                gap: 5px;
              }
              .rsc-ts-user-image {
                display: flex;
                align-items: center;
              }
              .rsc-ts-bubble {
                border-radius: 0px 6px 6px 6px;
                border: 1px solid #dddee2;
                background: #f7f7f8;
                margin-bottom: 0;
                padding: 12px;
              }
              .rsc-os-options {
                margin: 2px 0 12px 40px;
              }
            }
          }
        }
      }
    }
  }
}
.image-preview,
.user-upload-image-preview {
  padding: 10px;
  width: fit-content;
  position: relative;
  img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
  }
  .remove-icon {
    position: absolute;
    top: -4px;
    right: -4px;
    cursor: pointer;
    svg {
      fill: #d2042d;
    }
  }
}
.iAuOXT {
  opacity: 1;
  padding: 10px;
}
.custome-input-container {
  display: flex;

  .speech-button-container {
    // width: 100%;
    display: flex;
    margin: auto;
    padding: 5px 6px;
  }
  .rsc-submit-button {
    // top: 50%;
    // transform: translateY(-50%);
  }
  .iUYVrA {
    position: unset !important;
    padding: 5px !important;
    opacity: 1 !important;
    fill: #212529 !important;
  }
  .iUYVrA:not(:disabled):hover {
    opacity: 1;
  }
}
/* ************************************************** media query **************************************************  */

@media (max-width: 63em) {
  .section-dashboard-home {
    .comman-container {
      .inner-container {
        display: flex;
        padding: 0px !important;
        .column-1 {
          display: none;
        }
        .column-2 {
          padding: 16px;
          .rsc {
            width: 100%;
            height: 100%;
            .rsc-ts {
              margin-top: 10px !important;
            }
            .rsc-container {
              width: 100%;
              height: 100%;
              .rsc-header {
                border-bottom: 1px solid #ebebeb;
                background: #0290de;
                color: white;
                .lifvqk {
                  font-weight: 600;
                  font-size: 30px;
                  line-height: 38px;
                  color: #000000;
                }
              }
              .rsc-footer {
                border: 1px solid #ebebeb;
              }
              .rsc-content {
                height: 70vh;
              }
            }
          }
        }
      }
    }
  }
}

.chat-window-image-preview {
  height: calc(70vh - 100px) !important;
}
