.contact-us {
  display: flex;
  gap: 20px;
  .left {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      color: #0d0e14;
      text-align: start;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 44px; /* 122.222% */
    }
    .content {
      color: #626262;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 20px;
      h5 {
        font-weight: 600;
        color: #0d0e14;
      }
      ul {
        padding-left: 20px;
        padding-top: 10px;
        margin: 0;
        list-style: none;
      }
    }
    .form-wrapper {
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .input-wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;
          label {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
          input {
            border-radius: 4px;
            border: 1px solid #dfe3e6;
            background: #f5f5f5;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            padding: 10px;
            height: 40px;
          }
          textarea {
            height: 110px;
            flex-shrink: 0;
            border-radius: 4px;
            border: 1px solid #dfe3e6;
            background: #f5f5f5;
            padding: 10px;
          }
        }
        .submit-btn {
          button {
            display: flex;
            padding: 10px 24px;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .right {
    width: 50%;
    .image-wrapper {
      img {
        width: 100%;
      }
    }
    .content-wrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      background: #0290de;
      color: #fff;
      div {
        svg {
          width: 24px;
          height: 24px;
          font-weight: 700;
        }
        display: flex;
        gap: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
@media (max-width: 63em) {
  .contact-us {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .left {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .title {
        color: #0d0e14;
        text-align: start;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 44px; /* 122.222% */
      }
      .content {
        color: #626262;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 20px;
      }
      .form-wrapper {
        form {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
            label {
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
            input {
              border-radius: 4px;
              border: 1px solid #dfe3e6;
              background: #f5f5f5;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              padding: 10px;
              height: 40px;
            }
            textarea {
              height: 110px;
              flex-shrink: 0;
              border-radius: 4px;
              border: 1px solid #dfe3e6;
              background: #f5f5f5;
              padding: 10px;
            }
          }
          .submit-btn {
            button {
              display: flex;
              padding: 10px 24px;
              align-items: center;
              gap: 8px;
              border-radius: 4px;
            }
          }
        }
      }
    }
    .right {
      width: 100%;
      .image-wrapper {
        img {
          width: 100%;
        }
      }
      .content-wrapper {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        background: #0290de;
        color: #fff;
        div {
          svg {
            width: 24px;
            height: 24px;
            font-weight: 700;
          }
          display: flex;
          gap: 12px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
