/* App container styles */

.outermost-mobile-div {
  padding: 16px;
  .head-div {
    border-bottom: 1px solid var(--eaebf-0, #eaebf0);
    background: #fafbfc;
    h3 {
      padding: 12px;
      color: #4CAF50;
      // font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-transform: capitalize;
    }
  }
}

.main-patient-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 12px;
  font-family: Arial, sans-serif;
  // margin: 12px;
  // ChatApp.scss
  background-color: #fff;
  .header {
    width: 100%;
  }
  .search-div-2 {
    display: flex;
    flex-direction: row !important;
    margin: 10px auto;
    width: 90%;
    height: 40px;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--eaebf-0, #eaebf0);
    background: #fff;
    input {
      border: none;
      background-color: #fff;
      &:focus {
        outline: none;
      }
    }
  }
}

.header {
  flex-direction: column;
}

/* Heading styles */

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.patient-list {
  width: 100%;
  .patient {
    padding: 12px;
    // margin-bottom: 10px;
    background-color: #fff;
    border-bottom: 1.6px solid #eaebf0;
    cursor: pointer;
    &.active {
      border-left: 2px solid #0290de;
      // border-radius: 4px;
    }
    .profile-sec {
      display: flex;
      width: 100%;
      img {
        width: 48px;
        height: 48px;
        margin: auto;
        border-radius: 50%;
      }
      .text {
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
        text-transform: capitalize;
      }
      p {
        margin: 0 !important;
        text-align: left;
      }
      .name,
      .problem {
        color: var(--grey-110, #0d0e14);
        /* Medium : 14px | Line height : 20px */
        font-size: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .sex,
      .age {
        color: var(--grey, #5f6368);
        /* Regular : 14px | Line height : 20px */
        font-size: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

/* Chat list styles */

.chatList {
  display: flex;
  flex-direction: column;
}

.chat {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}

/* Chat messages styles */

.chatMessages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  .other-user,.user-self-mobile,.bot-mobile{
    display: flex;
    gap: 4px;
  }
  .user-detail{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
  }
  .message {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

/* Back button styles */

// button {
//     margin-top: 10px;
//     padding: 5px 10px;
//     background-color: #4caf50;
//     color: #fff;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//     &:hover {
//         background-color: #45a049;
//     }
// }
.mobile-view-patient {
  .profile {
    padding: 12px;
    border-bottom: 1px solid #eaebf0;
    width: 100%;
    span {
      margin: auto 0px;
    }
    .profile-sec {
      display: flex;
      img {
        width: 48px;
        height: 48px;
        margin: auto 0;
        border-radius: 50%;
      }
      .text {
        margin-left: 10px;
      }
      p {
        margin: 0 !important;
        text-align: left;
      }
      .name,
      .problem {
        color: var(--grey-110, #0d0e14);
        // text-align: center;
        /* Medium : 14px | Line height : 20px */
        font-size: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .sex,
      .age {
        color: var(--grey, #5f6368);
        /* Regular : 14px | Line height : 20px */
        font-size: 14px;
        font-family: Montserrat;
        line-height: 20px;
      }
    }
  }
  .profile-01 {
    display: flex;
    background-color: #fff;
    width: 100%;
    span {
      margin: auto 0;
      svg {
        margin: auto 0;
      }
    }
    .profile {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    .profile-picture {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    img {
      margin: auto 0;
    }
    h3 {
      color: var(--grey-110, #0d0e14);
      text-align: center;
      /* Medium : 14px | Line height : 20px */
      // font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: auto 0;
    }
    p {
      margin: auto;
      color: var(--grey, #5f6368);
      text-align: center;
      /* Regular : 14px | Line height : 20px */
      font-size: 14px;
      font-family: Montserrat;
      line-height: 20px;
      display: block;
    }
  }
  .mobile-container {
    background-color: #fff;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    .chatMessages {
      overflow: scroll;
      height: 67vh;
    }
    .user-self {
      margin: 10px 0;
    }
    .time-name {
      display: flex;
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
      span {
        margin: auto 0;
      }
      color: #000;
      /* Regular : 14px | Line height : 20px */
      // font-family: Montserrat;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .chatInput-mobile {
      width: 100%;
      display: flex;
      border-radius: 6px;
      border: 1px solid var(--eaebf-0, #eaebf0);
      background-color: #f5f5f5;
      /* Dash */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      .file-icon {
        margin: auto 10px;
        width: 40px;
        svg {
          fill: #717171;
        }
      }
      .submit {
        margin: auto 10px;
      }
      .inner-input {
        padding: 12px;
        width: -webkit-fill-available;
        border: none;
        background-color: #f5f5f5;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .message {
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    // max-width: 80%;
  }
  .patientMessage {
    background-color: #dcf8c6;
    align-self: flex-start;
  }
  .otherMessage {
    background-color: #e8e8e8;
    align-self: flex-end;
  }
  .profile-section {
    border: 1px solid #eaebf0;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #fff;
    max-width: 450px;
    overflow-y: scroll;
    margin-bottom: 40px;
    background-color: #eaebf0;
    .display-flex-mobile {
      display: flex;
      flex-direction: row;
      svg {
        margin: auto 0;
      }
      .h3-profile {
        padding: 12px;
        color: #272d37;
        font-size: 18px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-transform: capitalize;
        border-bottom: #ccc;
        width: 100%;
        margin-bottom: 0;
      }
    }
    .profile {
      background-color: #fff;
      .profile-sec {
        display: flex;
        flex-direction: column;
        img {
          width: 48px;
          height: 48px;
          margin: auto;
        }
        .text {
          margin: 12px;
          text-transform: capitalize;
        }
        p {
          margin: 0 !important;
          text-align: center;
        }
        .name,
        .problem {
          // color: var(--grey-110, #0D0E14);
          /* Medium : 14px | Line height : 20px */
          font-size: 14px;
          // font-family: Montserrat;
          font-weight: 500;
          line-height: 20px;
        }
        .sex,
        .age {
          color: var(--grey, #5f6368);
          /* Regular : 14px | Line height : 20px */
          font-size: 14px;
          font-family: Montserrat;
          line-height: 20px;
        }
      }
      .div-side-1 {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 6px;
        border: 1px solid var(--ebebeb, #ebebeb);
        margin: 12px auto;
        color: #000;
        /* Medium : 16px | Line height : 24px */
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 500;
        line-height: 24px;
      }
      .div-side-1 button {
        /* Remove all the styling */
        border: none;
        background-color: transparent;
        color: inherit;
        font: inherit;
        cursor: pointer;
        padding: 0;
      }
    }
    .docs {
      .each-doc {
        border-bottom: 1px solid #eaebf0;
        padding: 12px;
        display: flex;
        flex-direction: row !important;
        p {
          margin: 0 !important;
        }
        img {
          margin: 8px;
        }
        .text-m {
          margin-left: 12px;
          .txt-01 {
            color: #000;
            // text-align: center;
            /* Medium : 14px | Line height : 20px */
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 20px;
          }
          .txt-02 {
            color: #717171;
            font-size: 10px;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 14px;
            // text-align: center;
          }
        }
      }
    }
  }
  .file-container {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    // padding: 10px;
    // max-width: 80%;
    width: fit-content;
    // margin: 10px 0;
    padding: 4px 10px;
    cursor: pointer;
    background-color: #f2f2f2;
    span {
      margin: 0;
      max-width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-icon {
      width: 25px;
      height: 25px;
      svg {
        height: 100%;
        width: 100%;
        path {
          fill: #000000;
        }
      }
    }
    // background-color: #f2f2f2;
  }
  .file-container.self {
    svg path {
      fill: #958989;
    }
    background-color: #0290de;
    color: #f2f2f2;
    margin-left: auto;
    margin-right: 2px;
  }
}

//********************************************
.patient {
  background-color: #fff;
  border-radius: 4px;
  // padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f2f2f2;
  }
}

.mobile-view-patient {
  .profile-sec {
    display: flex;
    align-items: center;
  }
  .patient-pic {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  .sex,
  .age {
    font-size: 12px;
    color: #666;
  }
  .problem {
    font-size: 14px;
    color: #999;
  }
  .patient.mobile-container {
    background-color: #fff;
    padding: 10px;
  }
  .profile-01 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .profile-picture {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
  .profile h3 {
    font-size: 20px;
    font-weight: bold;
    // margin-bottom: 5px;
    text-transform: capitalize;
  }
  .profile p {
    font-size: 14px;
    color: #666;
  }
  .chatMessages {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  .bot-mobile,
  .user-self-mobile,
  .other-user {
    // background-color: #f2f2f2;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    img {
      height: 100%;
      width: 24px;
    }
  }
  .bot-mobile .name,
  .user-self-mobile .name,
  .other-user .name {
    font-size: 12px;
    font-weight: bold;
    color: #666;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
  .bot-mobile .time-stamp,
  .user-self-mobile .time-stamp,
  .other-user .time-stamp {
    font-size: 10px;
    color: #999;
  }
  .bot-mobile .message,
  .user-self-mobile .message,
  .other-user .message {
    font-size: 14px;
    color: #333;
  }
  .user-self-mobile {
    align-self: flex-end !important;
    align-items: flex-end !important;
    .message {
      background-color: #0290de;
      color: #fff;
    }
  }
  .file-view {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    .file-icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .file-info {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: 10px;
      p {
        font-size: 14px;
        color: #333;
        margin: 0;
      }
      span {
        font-size: 12px;
        color: #666;
      }
    }
    .file-actions {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  .chatInput-mobile {
    display: flex;
    align-items: center;
    border-top: 1px solid #f2f2f2;
    margin-top: 10px;
  }
  .inner-input {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    margin-right: 10px;
  }
  .submit {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .profile-section {
    background-color: #fff;
    padding: 20px;
    .display-flex-mobile {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 10px;
      }
      .h3-profile {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
      }
    }
    .profile {
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        // border-radius: 50%;
        margin-right: 20px;
      }
      .name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .sex,
      .age {
        font-size: 14px;
        color: #666;
      }
      hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: none;
        border-bottom: 1px solid #f2f2f2;
      }
    }
    .div-side-1 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
        margin-right: 10px;
      }
      button {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: bold;
        color: #666;
        cursor: pointer;
      }
    }
    .docs {
      margin-top: 20px;
      .each-doc {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          width: 24px;
          height: 30px;
          object-fit: cover;
          margin-right: 10px;
        }
        .text-m {
          flex: 1;
          .txt-01 {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
          }
          .txt-02 {
            font-size: 12px;
            color: #666;
            margin: 0;
          }
        }
        img:nth-child(2) {
          margin-right: 5px;
        }
      }
    }
  }
}
