.modal-detail {
  //   width: min(390px, 100%);
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 12px;
  width: min(100%, 525px);

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // width: min(100%, 300px);
    .video-wrapper {
      position: relative;
      .btn{
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: #0290de;
      }
    }
    video,
    img {
      width: 100%;
      border-radius: 3px;
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      gap: 20px;
      button {
        // margin-right: 10vw;
        width: 145px;
        padding: 5px 15px;
        border-radius: 3px;
      }
    }
    .primary-button {
      width: 145px;

      padding: 5px 15px;
      border-radius: 3px;
    }
  }
}
