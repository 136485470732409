@import "../../../../index.css";

.section-specialty {

    .inner-container {
        display: flex;

        .left-column {
            display: flex;
            flex-direction: column;
            flex: 0.9;

            .greeting-message {
                width: 100%;
                padding: 65px;
                color: #0A54A2;
                font-style: normal;
                font-weight: 500;
                font-size: 42px;
                line-height: 52px;
                display: flex;

                span {
                    margin: auto;

                }
            }


        }

        .right-column {
            background: #E6E8E9;
            flex: 1;
            position: relative;

            .container-wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                width: min(596px, 100%);
                height: 370.58px;
                gap: 24px;
                padding: 10px;

                .container-wrapper-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                }

                progress {
                    width: 100%;
                    height: 18px;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 33px;
                    color: rgba(18, 31, 62, 0.8);
                }

                .sub-title {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 33px;
                    color: rgba(18, 31, 62, 0.8);

                }

                .input-container {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    label {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: 0.150375px;
                        color: #2C3E4A;
                    }

                    input {
                        background: #F5F5F5;
                        border: 1px solid #DFE3E6;
                        border-radius: 4px;
                        height: 40px;
                        width: 100%;
                        padding-left: 16px;
                        outline: none;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                    }

                    .multiSelectContainer li {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 16px;
                    }


                    .input-inner-container {
                        position: relative;

                        i {
                            position: absolute;
                            top: 50%;
                            left: 16px;
                            transform: translateY(-50%);
                            font-size: 16px;

                        }



                        input {
                            padding-left: 16px;

                        }

                        .Expertise {
                            padding-left: 16px;

                        }

                    }

                    textarea {
                        background: #F5F5F5;
                        border: 1px solid #DFE3E6;
                        border-radius: 4px;
                        height: 91px;
                        width: 100%;
                        padding-left: 16px;
                        outline: none;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                    }

                    .pincode-state-city {
                        display: flex;
                        gap: 13px;


                    }

                }

                .button-container {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;

                    .next {
                        width: 79px;
                        height: 40px;
                        border-radius: 4px;
                    }

                    .prev {
                        width: 108px;
                        height: 40px;
                        border-radius: 4px;
                    }

                    .end {
                        display: flex;
                        gap: 12px;
                    }
                }



            }
        }

    }

}

/* ************************************************** media query **************************************************  */


@media (max-width: 63em) {
    .section-specialty {
        .inner-container {
            .left-column {
                display: none;
            }

            .right-column {
                position: unset;

                .container-wrapper {
                    padding: 0 45px;
                    position: unset;
                    transform: unset;
                    height: 560px;
                    margin: auto;

                    .container-wrapper-inner {
                        margin: auto;
                    }

                    .title {
                        padding: 0;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 33px;
                    }

                    .sub-title {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 33px;

                    }
                }

            }

        }
    }


}