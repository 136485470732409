.terms-condition {
  width: min(1110px, 100%);
  padding: 80px 20px;
  display: flex;
  margin: auto;
  flex-direction: column;
  gap: 40px;
  .title {
    color: var(--Grey-110, #0d0e14);
    // text-align: center;
    font-size: 36px;
    font-weight: 600;
  }
  .sub-title {
    display: flex;
    flex-direction: column;
    gap: 20px;
    span {
      font-size: 16px;
      font-weight: 500;
    color: #898787;

    }
    strong{
      font-size: 18px;
      font-weight: 600;

    }
  }
  .contant {
    color: #898787;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h5{
      color: black;
      font-weight: 600;
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-left: 40px;
      span {
        display: flex;
        flex-direction: column;
      }
    }
    strong {
      color: black;
    }
  }
}
