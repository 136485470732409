@import "../../../../index.css";

.section-hours {
  .inner-container {
    display: flex;

    .left-column {
      display: flex;
      flex-direction: column;
      flex: 0.9;

      .greeting-message {
        width: 100%;
        padding: 65px;
        color: #0a54a2;
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 52px;
        display: flex;

        span {
          margin: auto;
        }
      }
    }

    .right-column {
      background: #e6e8e9;
      flex: 1;
      position: relative;

      .container-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: min(596px, 100%);
        height: fit-content;
        min-height: 370.58px;
        gap: 24px;
        padding: 10px;

        .container-wrapper-inner {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        progress {
          width: 100%;
          height: 18px;
        }

        .title {
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: rgba(18, 31, 62, 0.8);
        }

        .sub-title {
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: rgba(18, 31, 62, 0.8);
        }
        .label-container {
          display: flex;
          justify-content: space-between;
        }

        .input-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin: auto;
          .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2px;
            .input-inner-container {
              display: flex;
              flex-direction: row;
              gap: 12px;
              margin: auto;
              // width: min(286px, 100%);
              .md-wrapper {
                display: flex;
                gap: 0.5vmax;
                flex-direction: column;
              }
              .btn-wrapper {
                position: relative;
                width: 6vw;
                .rm-btn {
                  font-size: 0.8vw;
                  height: 35px;
                  width: 6vw;
                  bottom: 0;
                  position: absolute;
                }
              }

              span {
                font-weight: 500;
                font-size: 1.2vw;
                line-height: 33px;
                color: rgba(18, 31, 62, 0.8);
                text-align: center;
              }
            }
            .input-error {
              color: red;
              font-size: 1vw;
            }
          }

          .form-label {
            font-weight: 400;
            font-size: 16px;
            display: flex;
            margin: auto;
            color: rgba(18, 31, 62, 0.8);
          }

          input {
            background: #f5f5f5;
            border: 1px solid #dfe3e6;
            border-radius: 4px;
            height: 35px;
            width: min(14vw, 100%);
            padding-left: 16px;
            outline: none;
            font-weight: 400;
            font-size: 1.1vw;
            line-height: 16px;
          }

          select {
            height: 35px;
            border: none;
            outline: none;
          }
        }

        .add-slot {
          display: flex;
          justify-content: center;
          width: 100%;
          button {
            width: 90px;
            height: 35px;
            border-radius: 4px;
          }
        }

        .button-container {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;

          .next {
            width: 79px;
            height: 40px;
            border-radius: 4px;
          }

          .prev {
            width: 108px;
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

/* ************************************************** media query **************************************************  */

@media (max-width: 63em) {
  .section-hours {
    .inner-container {
      .left-column {
        display: none;
      }

      .right-column {
        position: unset;

        .container-wrapper {
          padding: 0 45px;
          position: unset;
          transform: unset;
          height: 560px;
          margin: auto;

          .container-wrapper-inner {
            margin: auto;
          }

          .title {
            padding: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 33px;
          }

          .sub-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 33px;
          }
          .input-container {
            .input {
              font-size: 14px;
              width: 100px;
              text-align: center;
              height: 35px;
              line-height: unset;
            }
            .input-wrapper {
                
              .input-inner-container {
                .md-wrapper {
                  span {
                    font-size: 18px;
                  }
                }
                .btn-wrapper {
                  width: 70px;
                  .rm-btn {
                    width: 70px;
                    font-size: 14px;
                  }
                }
              }
              .input-error{
                font-size: 12px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
