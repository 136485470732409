/* Colors */

$primary-color: #0290de;
$secondary-color: #6c757d;
$background-color: #f8f9fa;
$text-color: #333333;

/* Fonts */

$title-font: "Montserrat",
Arial,
sans-serif;
$event-font: "Montserrat",
Arial,
sans-serif;

/* Outer container */


/* Side navigation */


/* Calendar container */

.calendar-container {
    flex: 1;
    margin: 20px;
    text-transform: capitalize;
    a{
        color: #0290de;
    }
}


/* Calendar header */

.fc-toolbar {
    background-color: $primary-color;
    border: none;
    padding: 15px;
    border-radius: 3px;

}

.fc-toolbar-title {
    font-family: $title-font;
    font-size: 20px;
    color: #ffffff;
    margin: 0;
}


/* Calendar header buttons */

.fc-button {
    font-family: $title-font;
    font-size: 14px;
    color: #ffffff;
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 5px;
    padding: 8px 12px;
    margin-right: 10px;
    cursor: pointer;
    &.fc-state-active {
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
    }
}


/* Calendar content */

.fc-view {
    background-color: $background-color;
}


/* Calendar time slots */

.fc-timegrid-axis {
    background-color: $background-color;
}

.fc-slats td,
.fc-slats th {
    border-color: $background-color;
}


/* Calendar events */

.fc-event {
    background-color: $primary-color;
    color: #ffffff;
    border: none;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: $event-font;
    font-size: 14px;
    &.fc-start {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &.fc-end {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}


/* Event title */

.fc-event-title {
    margin: 0;
}


/* Event time */

.fc-event-time {
    font-weight: bold;
    margin-right: 5px;
}


/* Event description */

.fc-event-description {
    margin: 0;
}


/* Hover effect on events */

.fc-event:hover {
    background-color: lighten($primary-color, 10%);
}


/* Today's date */

.fc-today {
    background-color: lighten($primary-color, 30%);
    border-radius: 50%;
    color: #ffffff;
}


/* Previous and next arrows */

.fc-prev-button,
.fc-next-button {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    background-color: $primary-color;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
}


/* Disabled state for previous and next arrows */

.fc-state-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


/* Customize scrollbar */

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 576px) {
    .fc-view-harness {
        height: 70vh !important;
    }
    .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
        margin-left: 0px !important
    }
    .fc-button-primary {
        margin-bottom: 20px;
    }
    .fc-header-toolbar {
        gap: 20px;
    }
    .fc-toolbar-chunk {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .fc-col-header-cell-cushion {
        font-size: 14px;
    }
    .fc-timegrid-axis-cushion {
        text-align: center;
    }
    .calendar-container {
        margin: 0;
    }
}