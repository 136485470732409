@import "../../index.css";
.section-footer {
  .comman-container {
    width: 100%;
    .inner-container {
      padding: 80px 70px 40px;
      background: #f8f8f8;
      .content-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 11%;
        .link {
          width: 100%;
          ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            gap: 29px;
            li {
              width: 160px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.02em;
              color: rgba(0, 0, 0, 0.6);
              cursor: pointer;
              text-align: center;
            }
          }
        }
        .company-info {
          display: flex;
          flex-direction: column;
          gap: 24px;
          width: 417px;
          .heading {
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #0290de;
            img{
              max-width: 200px;
            }
          }
          .content {
            width: 351px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
          }
          .input-container {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            .email-input-wrapper {
              position: relative;
              width: fit-content;
              margin: auto;
              input {
                width: 290px;
                height: 40px;
                background: #fbfdfe;
                border: 1px solid #0d0e14;
                border-radius: 4px;
                outline: none;
                padding-left: 16px;
              }
              img {
                position: absolute;
                right: 11.67px;
                top: 12px;
                height: 16px;
                width: 16px;
              }
            }
            button {
              width: 119px;
              height: 40px;
              background: #0290de;
              border-radius: 4px;
              border: none;
              color: #fffffe;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
            }
          }
          .social-link {
            display: flex;
            gap: 24px;
            img {
              width: 30px;
              height: 30px;
            }
          }
          .toll-free-number {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #3a3a3a;
          }
        }
      }
      .terms {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        .terms-wrapper {
          display: flex;
          gap: 24px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.6);
          a {
            color: rgba(0, 0, 0, 0.6) !important;
          }
        }
      }
    }
  }
}

/* ************************************************** media query **************************************************  */

@media (max-width: 63em) {
  .section-footer {
    .comman-container {
      .inner-container {
        padding: 24px 16px;
        text-align: center;
        .content-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 12%;
          .link {
            width: 100%;
            ul {
              list-style: none;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              gap: 16px;
              padding-left: 0;
              li {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
          .company-info {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            .heading {
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: #0290de;
              img{
                margin: auto;

              }
            }
            .content {
              width: 100%;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.6);
            }
            .input-container {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              gap: 8px;
              .email-input-wrapper {
                position: relative;
                width: fit-content;
                margin: auto;
                input {
                  width: 290px;
                  height: 40px;
                  background: #fbfdfe;
                  border: 1px solid #0d0e14;
                  border-radius: 4px;
                  outline: none;
                  padding-left: 16px;
                  margin: auto;
                }
                img {
                  position: absolute;
                  right: 11.67px;
                  top: 12px;
                  height: 16px;
                  width: 16px;
                }
              }
              button {
                width: 119px;
                height: 40px;
                background: #0290de;
                border-radius: 4px;
                border: none;
                color: #fffffe;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                margin: auto;
              }
            }
            .social-link {
              display: flex;
              gap: 24px;
              margin: auto;
              img {
                width: 38px;
                height: 38px;
              }
            }
            .toll-free-number {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #3a3a3a;
            }
          }
        }
        .terms {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          .terms-wrapper {

            display: flex;
            gap: 24px;
          }
        }
      }
    }
  }
}
