.book-appointment {
  width: min(390px, 100%);
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  gap: 16px;
  .heading {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .appointment-date {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;

    input {
      padding: 5px;
      border: 1px solid black;
      border-radius: 4px;
    }
  }
  label {
    font-size: 14px;
    color: #434343;
    font-weight: 500;
    line-height: 20px;
  }
  .appointment-slots-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .appointment-slots {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 5px;
      max-height: 42vh;
      overflow: auto;
      .btn-book {
        border-radius: 4px;
        padding: 5px;
        font-weight: 500;
        cursor: pointer;
        // background: #0290de;
      }
    }
    button{
      margin: auto;
      padding: 5px 10px;
      border-radius: 4px;
      margin-top: 15px;
    }
  }
}
