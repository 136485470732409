.plan-model-main {
  position: absolute;
  width: 600px;
  // max-width: 600px;
  background-color: white;
  border-radius: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;

  .cross {
    align-self: flex-end;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px 34px 34px;

    h3 {
      color: #272d37;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-transform: capitalize;
    }

    p {
      color: #121f3e;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
    }

    .plans {
      display: flex;
      justify-content: center;
      gap: 24px;

      .plan-details {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;

        p {
          text-align: left;
        }

        .duration {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .price {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;

          span{
            padding: 4px;
            margin-left: 10px;
            border-radius: 4px;
            border: 1px solid #0290de1a;
            background:#E6EEFF;
            color: #000;
            font-size: 10px;
            font-weight: 500;
          }
        }

        .btn {
          display: flex;
          padding: 10px 0;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: 4px;
          background: #0290de;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }

        .plan-desc {
          color: #5f6368;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
    .plan-model-main{
        width: 90vw;
        // width: unset;
        .container{
            padding: 2px 24px 24px;

            .plans{
                gap: 16px;

                .plan-details{
                    padding: 0;

                    .price{
                        span{
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 450px) {
    .plan-model-main{
        width: 95vw;
        .container{
            padding: 2px 14px 14px;

            .plans{
                .plan-details{
                    .price{
                        font-size: 20px;
                        span{
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }
}
