@import "bootstrap/dist/css/bootstrap.min.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Montserrat";
  /*Can be any text*/
  src: local("PingFang-Jian"),
    url("./Assets/fonts/PingFang-Jian.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* style guide */

:root {
  --clr-brown-400: #ae6136;
  --clr-brown-500: #a54a18;
  --clr-grey: #b4b4b4;
  --clr-white: #ffffff;
  --clr-black: #000000;
  --ff-primery: "Montserrat", sans-serif;
  --ff-body: var(--ff-primery);
  --ff-heading: var(--ff-primery);
  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;
  --fw-600: 600;
  --fs-100: 0.625rem;
  --fs-200: 0.75rem;
  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-600: 1rem;
  --fs-500: 0.9375rem;
  --fs-800: 1.125rem;
  --fs-020: 1.25rem;
  --fs-204: 1.5rem;
  --fs-306: 2.25rem;
  --fs-040: 2.5rem;
  --fs-408: 3rem;
  --fs-702: 4.5rem;
  --sc-width: 1440px;
  --sc-height: 535px;
  --fs-nav: var(--fs-200);
  --fs-f-link: var(--fs-200);
  --fs-f-titel: var(--fs-400);
  --fs-primery-heading: var(--fs-306);
  --fs-secondary-heading: var(--fs-408);
  --fs-content: var(--fs-600);
  --fs-price: var(--fs-600);
  --fs-carousel-content: var(--fs-306);
  --fs-email: var(--fs-200);
  --size-100: 0.625rem;
  --size-600: 1rem;
  --size-404: 2.75rem;
  --size-050: 3.125rem;
  --size-504: 3.375rem;
  --size-080: 5rem;
  --size-120: 7.5rem;
  --size-370: 23.125rem;
  --size-227: 14.1875rem;
}

@media (min-width: 50em) {
  :root {
    --fs-titel: 1.25rem;
    --fs-content: 0.75rem;
  }
}

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */

html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */

html,
body {
  height: 100%;
  font-family: "Montserrat" !important;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

body a {
  text-decoration: none;
}

.modal::-webkit-scrollbar {
  display: none;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.comman-container {
  width: 100%;
  --max-width: var(--sc-width);
  --padding: 0.1rem;
  /* width: min(var(--max-width), 100% - (var(--padding) *2)); */
  margin-inline: auto;
  /* padding: 0 0 75px 0; */
}

/* color  */

.text-black {
  color: var(--clr-black);
}

.text-brown {
  color: var(--clr-brown-500);
}

.text-white {
  color: var(--clr-white);
}

.text-grey {
  color: var(--clr-grey);
}

.text-dk-green {
  color: rgb(0, 150, 136);
}

.text-green {
  color: #39b54a;
}

.text-dk-red {
  color: #e54d42;
}

.text-primary {
  color: #007aff;
}

.fs-100 {
  font-size: var(--fs-100);
}

.fs-200 {
  font-size: var(--fs-200);
}

.fs-300 {
  font-size: var(--fs-300);
}

.fs-400 {
  font-size: var(--fs-400);
}

.fs-600 {
  font-size: var(--fs-600);
}

.fs-500 {
  font-size: var(--fs-500);
}

.fs-800 {
  font-size: var(--fs-800);
}

.fs-020 {
  font-size: var(--fs-020);
}

.fs-204 {
  font-size: var(--fs-204);
}

.fs-306 {
  font-size: var(--fs-306);
}

.fs-040 {
  font-size: var(--fs-040);
}

.fs-408 {
  font-size: var(--fs-408);
}

.fs-702 {
  font-size: var(--fs-702);
}

.fw-regular {
  font-weight: var(--fw-regular);
}

.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}

.fw-bold {
  font-weight: var(--fw-bold);
}

.fw-600 {
  font-weight: var(--fw-600);
  font-weight: var(--fw-600);
}

.fs-nav {
  font-size: var(--fs-nav);
}

.fs-f-link {
  font-size: var(--fs-f-link);
}

.fs-f-titel {
  font-size: var(--fs-f-titel);
}

.fs-primery-heading {
  font-size: var(--fs-primery-heading);
  line-height: 1.1;
}

.fs-secondary-heading {
  font-size: var(--fs-secondary-heading);
  line-height: 1.1;
}

.fs-content {
  font-size: var(--fs-content);
}

.fs-price {
  font-size: var(--fs-price);
}

.fs-carousel-content {
  font-size: var(--fs-carousel-content);
}

.fs-email {
  font-size: var(--fs-email);
}

.padding-block-100 {
  padding-block: var(--size-100);
}

.padding-block-404 {
  padding-block: var(--size-404);
}

.padding-block-080 {
  padding-block: var(--size-080);
}

.padding-block-120 {
  padding-block: var(--size-120);
}

.padding-block-370 {
  padding-block: var(--size-370);
}

.padding-block-227 {
  padding-block: var(--size-227);
}

.padding-block-050 {
  padding-block: var(--size-050);
}

.padding-block-504 {
  padding-block: var(--size-504);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bg-green {
  background-color: #39b54a !important;
  color: white !important;
}

.bg-violet {
  background-color: #6739b6 !important;
  color: white !important;
}

.bg-red {
  background-color: #ff4019 !important;
  color: white !important;
}

.bg-blue {
  background-color: #007aff !important;
  color: white !important;
}

.bg-dk-red {
  background-color: #e54d42 !important;
  color: white !important;
}

.bg-vi-rd {
  background-image: linear-gradient(160deg, #6739b6 50%, #e54d42 0);
  color: white !important;
}

.bg-vi-gr {
  background-image: linear-gradient(160deg, #6739b6 50%, #39b54a 0);
  color: white !important;
}

.shape-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: auto;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex;
}

.modal-body {
  padding: 0;
}

/* button style */

button {
  cursor: pointer;
  border-radius: 4px;
}

.primary-button {
  background: #0290de;
  color: #fffffe;
  outline: none;
  border: none;
  transition: all 200ms;
  border: 1px solid #0290de;

}

.primary-button:hover {
  background: #0272af;
  color: #fffffe;

}

.outline-button {
  border: 1px solid #0290de;
  /* background: #F8F8F8; */
  background: transparent;
  color: #0290de;
}

.outline-button-active,.outline-button:hover {
  background: #0272af;
  color: #fffffe;
  background: #0290de;
}


.disable-button {
  background: rgba(2, 144, 222, 0.6);
  color: #fffffe;
  border-radius: 4px;
  border: none;
}

.disable-button:hover {
  background: #0272af;
  color: #fffffe;
  background: #0290de;
}

.disable-hover {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

input {
  &:focus {
    outline: none !important;
  }
}

.error {
  color: #ff0000;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: You can adjust the margin to control spacing */
}
