@import "../../../index.css";

.section-book-appointment {
    .commn-container {
        .inner-container {
            display: flex;
            flex-direction: column;
            padding: 41px;
            background: #FFFFFF;

            .choose-dentist-containner {
                border-bottom: 0.847247px solid #CDCDCD;
                margin-bottom: 20px;

                .heading {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #0D0E14;
                    margin-bottom: 14px;
                }

                .sub-title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: capitalize;
                    color: #333333;
                    margin-bottom: 20px;
                }

                .dentist-list-wrapper {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(417px, 417px));
                    gap: 24px;
                    margin-bottom: 24px;

                    .dentist-profile-wrapper {
                        width: 417px;
                        height: fit-content;
                        display: flex;
                        gap: 12px;
                        padding: 12px 16px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        border-radius: 6px;

                        img {
                            width: 82px;
                            height: 82px;
                        }

                        .dentist-detail {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;

                            .name {
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 30px;
                                text-align: center;
                                color: #0D0E14;
                            }

                            .expertise {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 18px;
                                color: rgba(0, 0, 0, 0.6);

                            }

                            .social-links {
                                display: flex;
                                gap: 10px;

                                img {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }

                        .view-profile {
                            display: flex;
                            align-items: flex-end;

                            button {
                                width: 66px;
                                height: 32px;
                                border: 1px solid #D6E7EF;
                                border-radius: 4px;
                            }
                        }

                    }

                }

                .pagination-wrapper {
                    .pagination {
                        gap: 8px;
                    }

                }
            }

            .choose-date {
                margin-bottom: 20px;

                .date {
                    width: 160px;
                    height: 36px;
                    border-radius: 4px;
                    border: none;
                    margin-right: 24px;

                    input {
                        display: none;
                    }

                }

                .selected-date {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #333333;
                }

            }

            .choose-slot {
                margin-bottom: 20px;

                .title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-transform: capitalize;
                    color: #0D0E14;
                    margin-bottom: 12px;

                }

                .dentist-name {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    color: #0D0E14;
                    margin-bottom: 24px;
                    text-align: start;
                }

                .slot-wrapper {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, 117px);
                    gap: 24px;

                    .slot {
                        width: 117px;
                        height: 54px;
                        border: 1px solid #2C3E4A;
                        box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.16);
                        border-radius: 4px;
                        background: unset;
                    }

                }
            }

            .line {
                width: 1005;
                height: 0px;
                margin-bottom: 20px;
                border: 0.847247px solid #CDCDCD;
            }

            .message {
                .heading {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-transform: capitalize;
                    color: #0D0E14;
                    margin-bottom: 20px;
                }

                textarea {
                    padding: 12px;
                    width: 677.8px;
                    height: 89.81px;
                    margin-bottom: 20px;
                    background: #FBFCFF;
                    border: 0.807712px solid #EBEBEB;
                    border-radius: 3.38899px;

                }

            }

            .confirm-btn {
                button {
                    width: 283px;
                    height: 40px;
                    background: #0290DE;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFE;

                }
            }
        }
    }
}