.spinner-parent {
  background: rgb(0 0 0 / 67%);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 100000;
}
.loader-ur {
  width: 6vmax;
  height: 6vmax;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-ur:before {
  content: "";
  width: 6vmax;
  height: 5px;
  background: #999;
  position: absolute;
  top: 7.5vmax;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader-ur:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #0290de;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}
