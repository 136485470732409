@import "../../index.css";


.section-home {
    .comman-container {
        .inner-container {
            height: 810px;
            background: #E6E8E9;
            display: flex;

            .left {
                padding: 286px 0px 0px 165px;


                h1 {
                    width: 455px;
                    height: 120px;
                    font-weight: 400;
                    font-size: 48px;
                    line-height: 60px;
                    color: #121F3E;
                }

                h2 {
                    width: 393px;
                    height: 72px;

                    /* Regular : 16px | Line height : 24px */

                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* or 150% */


                    color: #121F3E;
                }

                button {
                    font-family: 'Montserrat';
                    width: 175px;
                    height: 40px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    align-items: center;
                    background-color: #0290DE;
                    border: none;
                    color: #FFFFFE;
                    margin: 0px;
                }
            }



            .right {
                // position: absolute;
                padding: 0px 165px 0px 0px;
                margin: auto;
                
                display: flex;
                align-items: center;
                border-radius: 0px 0px 0px 24px;
            }
        }
    }
}


/* ************************************************** media query **************************************************  */


@media (max-width: 63em) {
    .section-home {
        .comman-container {
            .inner-container {
                height: unset;
                background: #E6E8E9;
                display: flex;
                flex-direction: column;
                gap: 16px;
    
                .left {
                    padding:16px;
                    margin: 0 auto;
                    h1 {
                        width: 100%;
                        height: fit-content;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 42px;
                        color: #121F3E;
                    }
    
                    h2 {
                        width: 100%;
                        height: fit-content;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                    }
    
                    button {
                        width: 128px;
                        height: 40px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        align-items: center;
                        background-color: #0290DE;
                        border: none;
                        color: #FFFFFE;
                        margin: 0px;
                    }
                }
                .right {
                    margin: unset;
                    padding: 0;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

}