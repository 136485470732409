.outer-div {
    display: flex;
    background-color: #ebebeb;
    margin: auto;
    min-height: 100%;
}

.main-dashboard-div {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    overflow: auto;
    height: calc( 100vh - 84px);
    .txt-01 {
        color: var(--222222, #222);
        /* Medium : 36px | Line height : 44px */
        font-size: 36px;
        font-family: Montserrat;
        font-weight: 500;
        line-height: 44px;
    }
    .txt-02 {
        color: var(--grey, #5f6368);
        /* Regular : 16px | Line height : 24px */
        font-size: 16px;
        font-family: Montserrat;
        line-height: 24px;
    }
    .appointment-div {
        background-color: #fafbfc;
        .app-div-01 {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        .a-01 {
            margin-top: 36px;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            h3 {
                color: #272d37;
                /* Medium : 24px | Line height : 32px */
                font-size: 24px;
                font-family: Montserrat;
                font-weight: 500;
                line-height: 32px;
                // margin-bottom: 10px;
                margin: auto 0;
            }
            .search-div {
                display: flex;
                width: 290px;
                height: 32.4px;
                padding: 8px 12px;
                justify-content: space-between;
                align-items: center;
                border-radius: 4px;
                border: 1px solid #ccc;
                background: #fff;
                input[type="text"] {
                    // padding: 10px;
                    font-size: 14px;
                    border: none;
                    border-radius: 3px;
                    width: 100%;
                    flex: 1;
                    &:focus {
                        outline: none;
                    }
                }
                img {
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                }
            }
            select {
                display: flex;
                width: 165px;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 4px;
                border: 1px solid #dae0e6;
                color: #272d37;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                padding: 5px;
                margin: auto 0px ;
                margin-right: 10px;
                outline: none;
                
            }
            
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        background-color: #fff;
        padding: 20px;
        th,
        td {

            padding: 10px;
            color: var(--grey-110, #0d0e14);
            /* Medium : 16px | Line height : 24px */
            font-size: 16px;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 24px;
            max-width: 15vw;
            .img-001 {
                display: flex;
                padding: 5px;
                gap: 12px;
                width: 210px;
                cursor: pointer;
                img{
                    width: 48px;
                    height: 48px;
                    border-radius: 48px;
                }
                span {
                    margin: auto 0;
                }
            }
        }
        .action-btn{
            // background: #0290DE;
            // color: white;
            font-size: 12px;
            font-family: Montserrat;
            outline: none;
            // border: none;
            font-weight: 500;
            border-radius: 5px  ;
            padding: 3px;
        }
        option{
            background: white;
            color: black;
           
        }
       
        th {
            // background-color: #f5f5f5;
            font-weight: bold;
            border-bottom: 1px solid #ccc;
            color: #767b83;
            /* Medium : 14px | Line height : 20px */
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 20px;
        }
    }
    .hide-web {
        display: none;
    }
}

@media (max-width: 768px) {
    .main-dashboard-div {
        .txt-01 {
            color: var(--grey-110, #0D0E14);
            /* Medium : 16px | Line height : 24px */
            font-size: 24px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            margin: auto 5px;
        }
        .appointment-div {
            .a-01 {
                flex-direction: column;
                .search-div {
                    width: 100% !important;
                    margin-top: 10px;
                }
                .app-div-01 {
                    display: flex;
                    gap: 8px;
                    select {
                        padding: 5px !important;
                    }
                }
            }
        }
    }
    .hide-mobile {
        display: none;
    }
    .appointment-list {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        background-color: #fff;
        padding: 20px;
        .p-tag {
            color: #767b83;
            /* Medium : 14px | Line height : 20px */
            font-size: 14px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
        .profile-div {
            display: flex;
            // margin-bottom: 10px;
            gap: 20px;
            padding: 10px;
            box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
            cursor: pointer;
            img {
                width: 48px;
                height: 48px;
                margin: auto 0;
                border-radius: 48px;
            }
            .content-wrapper{
                display: flex;
                flex-direction: column;
                margin: auto 0px;
                gap: 5px;
                .title{
                    color: var(--grey-110, #0D0E14);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                }
                .content{
                    display: flex;
                    gap: 10px;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .txt-01 {
                color: var(--grey-110, #0D0E14);
                /* Medium : 16px | Line height : 24px */
                font-size: 16px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                margin: auto 5px;
            }
        }
        .info-div {
            background-color: #F4F4F4;
            // border: 1px solid #ddd;
            padding: 20px;
            border-radius: 4px;
            margin-bottom: 20px;
            .action-btn{
                margin-top: 12px;
                font-size: 12px;
                font-family: Montserrat;
                outline: none;
                font-weight: 500;
                border-radius: 5px;
                padding: 3px;
            }
        }
        .info-heading {
            color: var(--80, rgba(44, 62, 74, 0.80));
            font-size: 14px;
            // font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
        .info-divider {
            border-top: 1px solid #ccc;
            margin: 10px 0;
        }
        .info-data {
            margin: 5px 0;
            color: #767B83;
            font-size: 14px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
    .hide-web {
        display: block !important;
    }
}

@media (max-width: 360px) {
    .app-div-01 {
        flex-direction: column;
        select {
            width: 100%;
        }
    }
}