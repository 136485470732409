.membership-plan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 34px 34px;
  height: calc(100vh - 62px);
  gap: 24px;

  h3 {
    color: #0d0e14;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 140% */
    margin-bottom: 0px;
  }
  .user-type {
    display: flex;
    gap: 10px;
 
    button{
      padding: 5px 20px;
      border-radius: 4px;
      font-weight: 600;
    }
  }

  p {
    margin-bottom: 0px;
    width: min(600px, 100%);
    color: var(--black-alpha-60, rgba(0, 0, 0, 0.6));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    text-align: center;
  }
  .plan-not-found{
    font-weight: 600;
    margin-bottom: 40px;
  }

  .plans {
    display: flex;
    justify-content: center;
    gap: 24px;
    background: #ffffff;

    .plan-details {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 300px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 8px;
      gap: 12px;

      p {
        text-align: left;
      }

      .duration {
        color: #0d0e14;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }

      .price {
        color: var(--Grey-110, #0d0e14);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 133.333% */

        span {
          padding: 4px;
          margin-left: 10px;
          border-radius: 4px;
          border: 1px solid #0290de1a;
          background: #e6eeff;
          color: #000;
          font-size: 10px;
          font-weight: 500;
        }
      }

      .btn {
        display: flex;
        padding: 10px 0;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        background: #0290de;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      .plan-desc {
        color: var(--black-alpha-60, rgba(0, 0, 0, 0.6));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.28px;
      }
    }
  }
}
//   }

@media (max-width: 650px) {
  //   .plan-model-main{
  //       width: 90vw;
  //       // width: unset;
  .membership-plan-container {
    padding: 2px 24px 24px;
    height: unset;

    h3 {
      font-size: 34px;
    }
    p {
    }

    .plans {
      gap: 16px;
      flex-direction: column;

      .plan-details {
        // padding: 0;

        .price {
          span {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
//   }
