.about-us-conatiner {
  .about-us {
    width: min(100%, 700px);
    margin: auto;
    padding: 20px 24px;
    margin-bottom: 20px;
    .title {
      color: black;
      text-align: center;
      font-size: 38px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px; /* 122.222% */
      margin-bottom: 24px;
      margin-top: 5vw;
    }
    .content {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
     
    }
  }
  .what-we-do {
    margin-bottom: 40px;
    .row-1 {
      display: flex;
      gap: 10vw;
      align-items: center;
      margin-bottom: 40px;

      .image-wrapper {
        width: 41vw;
        height: 39vw;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          //   flex-shrink: 0;
          border-radius: 0px 40px 40px 0px;
        }
        .border-box {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 41vw;
          height: 39vw;
          flex-shrink: 0;
          border-radius: 0px 40px 40px 0px;
          border: 1px solid var(--0290-de, #0290de);
        }
      }
      .content-wrapper {
        width: 50%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: min(600px, 100%);
        .title {
          color: black;
          font-family: Montserrat;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px; /* 126.667% */
        }
        .content {
          color: #898787;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        
        }
      }
    }
    .row-2 {
      display: flex;
      gap: 10vw;
      align-items: flex-start;
      flex-direction: row-reverse;

      .image-wrapper {
        width: 41vw;
        height: 39vw;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          //   flex-shrink: 0;
          border-radius: 0px 40px 40px 0px;
          transform: scaleX(-1);
        }
        .border-box {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 1;
          width: 41vw;
          height: 39vw;
          flex-shrink: 0;
          border-radius: 0px 40px 40px 0px;
          transform: scaleX(-1);

          border: 1px solid var(--0290-de, #0290de);
        }
      }
      .content-wrapper {
        width: 50%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: min(600px, 100%);

        .content {
          display: flex;
          flex-direction: column;
          gap: 20px;
          color: #898787;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          h3{
            font-weight: 600;
          }
          h5{
            color: black ;
          }
        }
      }
    }
  }
  .our-team {
    margin: 80px 0px;
    .title {
      color: black;
      text-align: center;
      font-size: 38px;
      font-style: normal;
      font-weight: 500;
      line-height: 44px; /* 122.222% */
      margin: 40px 0px;
    }
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5vw;
      .profile-card {
        width: 200px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        img {
          width: 172px;
          height: 172px;
          border-radius: 172px;
          display: flex;
          margin: 0px auto;
        }
        .name {
          padding: 0px 20px;
          color: black;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
        }
        .rating {
          display: flex;
          gap: 5px;
          justify-content: center;
          svg {
            color: rgba(245, 198, 31, 1);
          }
        }
        .designation {
          color: black;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          //   line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}

@media (max-width: 63em) {
  .about-us-conatiner {
    .about-us {
      width: min(100%, 700px);
      margin: auto;
      padding: 20px 24px;
      margin-bottom: 20px;
      .title {
        color: black;
        text-align: center;
        font-size: 34px;
        font-style: normal;
        font-weight: 500;
        line-height: 44px; /* 122.222% */
        margin-bottom: 24px;
        margin-top: 5vw;
      }
      .content {
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
    .what-we-do {
      margin-bottom: 40px;
      .row-1 {
        display: flex;
        flex-direction: column;
        gap: 10vw;
        align-items: center;
        margin-bottom: 40px;

        .image-wrapper {
          width: 70vw;
          height: 65vw;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            //   flex-shrink: 0;
            border-radius: 0px 40px 40px 0px;
          }
          .border-box {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 70vw;
            height: 65vw;
            flex-shrink: 0;
            border-radius: 0px 40px 40px 0px;
            border: 1px solid var(--0290-de, #0290de);
          }
        }
        .content-wrapper {
          width: 50%;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: min(500px, 100%);
          .title {
            color: black;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 38px; /* 126.667% */
          }
          .content {
            color: #898787;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }
      }
      .row-2 {
        display: flex;
        gap: 10vw;
        flex-direction: column;

        align-items: center;

        .image-wrapper {
          width: 70vw;
          height: 65vw;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            //   flex-shrink: 0;
            border-radius: 0px 40px 40px 0px;
            transform: scaleX(-1);
          }
          .border-box {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1;
            width: 70vw;
            height: 65vw;
            flex-shrink: 0;
            border-radius: 0px 40px 40px 0px;
            transform: scaleX(-1);

            border: 1px solid var(--0290-de, #0290de);
          }
        }
        .content-wrapper {
          width: 50%;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: min(500px, 100%);

          .content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: #898787;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
    .our-team {
      margin-bottom: 80px;
      .title {
        color: black;
        text-align: center;
        font-size: 34px;
        font-style: normal;
        font-weight: 500;
        line-height: 44px; /* 122.222% */
        margin: 40px 0px;
      }
      .card-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15vw;
        .profile-card {
          width: 200px;
          display: flex;
          gap: 10px;
          flex-direction: column;
          img {
            width: 172px;
            height: 172px;
            border-radius: 172px;
            display: flex;
            margin: 0px auto;
          }
          .name {
            padding: 0px 20px;
            color: black;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
          }
          .rating {
            display: flex;
            gap: 5px;
            justify-content: center;
            svg {
              color: rgba(245, 198, 31, 1);
            }
          }
          .designation {
            color: black;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            //   line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
}
