.faqs {
  width: min(1110px, 100%);
  padding: 80px 20px;
  display: flex;
  margin: auto;
  flex-direction: column;
  gap: 40px;
  .headig-wrapper {
    color: var(--Grey-110, #0d0e14);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 122.222% */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .search-bar {
    display: flex;
    // padding: 20px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    background: #fbfcff;
    width: min(435.82px, 100%);
    height: 40.993px;
    flex-shrink: 0;
    padding: 0px 10px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    input {
      width: 100%;
      color: #222;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      border: none;
      outline: none;
      background: #fbfcff;
    }
    svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
  .question-answer-wrapper {
    // padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: min(606px, 100%);
    .title {
      color: #0d0e14;

      /* Semibold : 24px | Line height : 32px */
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      display: flex;
      flex-direction: column;
      gap: 12px;
      .subtitle {
        color:  rgba(0, 0, 0, 0.6);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
      }
    }
    .question-answer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .question {
        color: #454545;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
      .answer {
        color: rgba(0, 0, 0, 0.6);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        // line-height: 18px; /* 150% */
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
