@import "../../../../index.css";

.section-choose-one {
  .inner-container {
    display: flex;

    .left-column {
      // width: 708px;
      display: flex;
      flex-direction: column;
      flex: 0.9;

      .greeting-message {
        width: 100%;
        padding: 65px;
        color: #0a54a2;
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 52px;
        display: flex;

        span {
          margin: auto;
        }
      }
    }

    .right-column {
      background: #e6e8e9;
      flex: 1;
      position: relative;

      .container-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: min(596px, 100%);
        height: 370.58px;
        gap: 24px;

        .title {
          padding: 0px 76px;
          color: #2c3e4a;
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          text-align: center;
        }

        .option {
          display: flex;
          gap: 24px;
          margin: 0 auto;

          .patient {
            width: 145.36px;
            height: 135.34px;
            background: #ffffff;
            border: 2.14822px solid #dfe3e6;
            border-radius: 4px;
            gap: 14.16px;

            img {
              margin: 34.78px auto 0px;
            }

            div {
              margin: 14.16px auto 0px;
              text-align: center;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #2c3e4a;
            }
          }

          .dentist {
            width: 145.36px;
            height: 135.34px;
            background: #ffffff;
            border: 2.14822px solid #dfe3e6;
            border-radius: 4px;
            gap: 14.16px;

            img {
              margin: 34.78px auto 0px;
            }

            div {
              margin: 14.16px auto 0px;
              text-align: center;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #2c3e4a;
            }
          }
        }

        .or-signup-with {
          display: flex;
          justify-content: center;

          .line {
            width: 100px;
            height: 1px;
            border: 1px solid #d6d6d6;
            margin: auto 7.5px;
            flex: 1;
          }
        }

        .signup-option {
          display: flex;
          gap: 24px;
          margin: auto;
          flex-direction: column;
          width: 100%;

          .icon-container {
            display: flex;
            justify-content: center;
            gap: 24px;
            margin: auto;
            width: 100%;

            .icon {
              text-transform: uppercase;
              font-weight: 500;
              display: flex;
              gap: 15px;
              padding: 10px;
              width: 314px;

              background: #f8f8f8;
              border: 1px solid #dfe3e6;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 28.33px;
                height: 28.25px;
              }
            }
          }

          .have-account {
            display: flex;
            margin: auto;
            gap: 2px;
            color: #0c0c0c;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            font-family: "Montserrat";

            span {
              color: #0290de;
            }
          }
        }
      }
    }
  }
}

/* ************************************************** media query **************************************************  */

@media (max-width: 63em) {
  .section-choose-one {
    .inner-container {
      .left-column {
        display: none;
      }
      .right-column {
        position: unset;
        .container-wrapper {
          padding: 97px 13px;
          position: unset;
          transform: unset;
          height: unset;
          margin: auto;
          .title {
            padding: 0;
          }
        }
      }
    }
  }
}
