.outer-div {
    display: flex;
    background-color: #EBEBEB;
    margin: auto;
}

.display-flex {
    display: flex;
}

.outer-div {
    .container {
        padding: 0;
        margin: 0 auto;
        border-radius: 5px;
        margin-top: 30px;
        margin-bottom: 30px;
        height: fit-content;
    }
}

.main-patient-section {
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    // height: 90vh;
    overflow: hidden;
    border: 1px solid #D0D5DD;
    // width: 88%;
    max-width: 1100px;
    // ChatApp.scss
    background-color: #fff;
    .header {
        padding: 12px 24px;
        border-bottom: 1px solid #eaebf0;
        background-color: #FAFBFC;
        .search-div {
            display: flex;
            width: 290px;
            height: 40px;
            padding: 8px 12px;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            border: 1px solid var(--eaebf-0, #eaebf0);
            background: #fff;
            input {
                border: none;
                background-color: #fff;
                &:focus {
                    outline: none;
                }
            }
        }
        h2 {
            // font-family: Montserrat;
            text-transform: capitalize;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
            margin-right: 16px;
        }
    }
    .chat-app {
        display: flex;
        // height: 90%;
        overflow: hidden;
        width: 100%;
        justify-content: space-between;
    }
    .left-panel {
        // padding: 20px;
        background-color: #f1f1f1;
        // max-width: 295px;
        width: 25%;
        border-right: 1px solid #eaebf0;
        height: 731px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .patient-list {
        .patient {
            padding: 12px;
            // margin-bottom: 10px;
            background-color: #fff;
            border-bottom: 1px solid #eaebf0;
            cursor: pointer;
            &.active {
                border-left: 2px solid #0290de;
                // border-radius: 4px;
            }
            .profile-sec {
                display: flex;
                img {
                    width: 48px;
                    height: 48px;
                    margin: auto 0;
                    border-radius: 50%;
                }
                .text {
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    max-width: -webkit-fill-available;
                }
                p {
                    margin: 0 !important;
                    text-align: left;
                }
                .name,
                .problem {
                    // color: var(--grey-110, #0D0E14);
                    /* Medium : 14px | Line height : 20px */
                    font-size: 14px;
                    // font-family: Montserrat;
                    font-weight: 500;
                    line-height: 20px;
                }
                .sex,
                .age {
                    color: var(--grey, #5f6368);
                    /* Regular : 14px | Line height : 20px */
                    font-size: 14px;
                    font-family: Montserrat;
                    line-height: 20px;
                }
            }
        }
    }
}

.middle-panel {
    // padding: 20px;
    background-color: #fff;
    max-width: 1100px;
    height: 100%;
    width: 50%;
    .profile {
        padding: 12px;
        // border-bottom: 1px solid #eaebf0;
        border-bottom: 1px solid var(--d-0-d-5-dd, #D0D5DD);
        .profile-sec {
            display: flex;
            img {
                width: 48px;
                height: 48px;
                margin: auto 0;
            }
            .text {
                margin-left: 10px;
                text-transform: capitalize;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // width: 180px;
            }
            p {
                margin: 0 !important;
                text-align: left;
            }
            .name,
            .problem {
                // color: var(--grey-110, #0D0E14);
                /* Medium : 14px | Line height : 20px */
                font-size: 14px;
                // font-family: Montserrat;
                font-weight: 500;
                line-height: 20px;
            }
            .sex,
            .age {
                color: var(--grey, #5f6368);
                /* Regular : 14px | Line height : 20px */
                font-size: 14px;
                font-family: Montserrat;
                line-height: 20px;
            }
        }
    }
    .no-patient-selected {
        font-size: 18px;
        text-align: center;
        color: #999;
    }
    .chat {
        h3 {
            margin-bottom: 20px;
            font-size: 20px;
        }
        .message-list {
            .message {
                width: 500px;
                padding: 10px;
                margin-bottom: 10px;
                background-color: #fff;
                border-radius: 4px;
            }
        }
        // Add styles for chat input form
    }
}

.right-panel {
    border-left: 1px solid #eaebf0;
    padding: 20px;
    background-color: #fff;
    // max-width: 450px;
    width: 25%;
    .profile {
        .profile-sec {
            display: flex;
            flex-direction: column;
            img {
                width: 48px;
                height: 48px;
                margin: auto;
            }
            .text {
                margin: 12px;
                text-transform: capitalize;
            }
            p {
                margin: 0 !important;
                text-align: center;
            }
            .name,
            .problem {
                // color: var(--grey-110, #0D0E14);
                /* Medium : 14px | Line height : 20px */
                font-size: 14px;
                // font-family: Montserrat;
                font-weight: 500;
                line-height: 20px;
            }
            .sex,
            .age {
                color: var(--grey, #5f6368);
                /* Regular : 14px | Line height : 20px */
                font-size: 14px;
                font-family: Montserrat;
                line-height: 20px;
            }
        }
        .div-side-1 {
            overflow: hidden;
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            // align-self: stretch;
            border-radius: 6px;
            border: 1px solid var(--ebebeb, #ebebeb);
            margin: 12px auto;
            color: #000;
            /* Medium : 16px | Line height : 24px */
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 24px;
        }
        @media (max-width: 768px) {
            /* Styles for screens up to 768px wide */
            .div-side-1 {
                flex-direction: column;
                align-items: flex-start;
                text-align: center;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .button {
            cursor: pointer;
        }
        .div-side-1 button {
            /* Remove all the styling */
            border: none;
            background-color: transparent;
            color: inherit;
            font: inherit;
            cursor: pointer;
            padding: 0;
        }
    }
    .docs {
        .each-doc {
            border-bottom: 1px solid #eaebf0;
            padding: 12px;
            p {
                margin: 0 !important;
            }
            img {
                margin: 8px;
            }
            .text-m {
                margin-left: 12px;
                .txt-01 {
                    color: #000;
                    // text-align: center;
                    /* Medium : 14px | Line height : 20px */
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 500;
                    line-height: 20px;
                }
                .txt-02 {
                    color: #717171;
                    font-size: 10px;
                    font-family: Montserrat;
                    font-weight: 500;
                    line-height: 14px;
                    // text-align: center;
                }
            }
        }
    }
}

.mobile-view-patient {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .side-nav {
        display: none;
    }
    .main-patient-section {
        display: none;
    }
    .mobile-view-patient {
        display: block;
        width: 100%;
    }
}

@media (max-width: 768px) {
    /* Styles for screens up to 768px wide */
    .div-side-1 {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
    }
}