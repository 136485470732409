.search-div {
    display: flex;
    width: 290px;
    height: 40px;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    input[type="text"] {
        // padding: 10px;
        border: none;
        border-radius: 3px;
        flex: 1;
        &:focus {
            outline: none;
        }
    }
    img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
}

.thead-bkc {
    background-color: #EAEBF0;
    .your-visit {
        color: #272D37;
        /* Medium : 24px | Line height : 32px */
        font-size: 24px;
        // font-family: Montserrat;
        font-weight: 500;
        line-height: 32px;
        padding: 12px;
        width: 100%;
    }
}

.shared-files-tbody {
    .upload {
        img {
            margin-left: auto;
        }
    }
    .social-icon {
        justify-content: space-between;
        img {
            margin: 0 5px;
            width: 24px !important;
            height: 24px !important;
        }
    }
    .img-001 {
        img {
            width: 48px;
            height: 48px;
        }
    }
    p {
        margin: 0;
    }
    .txt-01 {
        margin-left: 12px;
    }
    .name {
        color: var(--grey-110, #0D0E14);
        text-align: center;
        /* Medium : 14px | Line height : 20px */
        font-size: 14px;
        // font-family: Montserrat;
        font-weight: 500;
        line-height: 20px;
    }
    .designation {
        color: var(--grey, #5F6368);
        text-align: center;
        /* Regular : 14px | Line height : 20px */
        font-size: 14px;
        // font-family: Montserrat;
        line-height: 20px;
    }
}

@media (max-width: 768px) {
    .search-div {
        display: none;
    }
}