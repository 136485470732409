.main-dashboard-div {
  .custome-slot {
    .input-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: auto;
      .input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .input-inner-container {
          display: flex;
          flex-direction: row;
          gap: 12px;
          width: min(286px, 100%);
          .md-wrapper {
            display: flex;
            gap: 0.5vmax;
            flex-direction: column;
          }
          .btn-wrapper {
            position: relative;
            width: 6vw;
            .rm-btn {
              font-size: 1.1vw;
              height: 35px;
              width: 6vw;
              bottom: 0;
              position: absolute;
            }
          }

          span {
            font-weight: 500;
            font-size: 1.2vw;
            line-height: 33px;
            color: rgba(18, 31, 62, 0.8);
            text-align: center;
          }
        }
        .input-error {
          color: red;
          font-size: 1vw;
        }
      }

      .form-label {
        font-weight: 400;
        font-size: 16px;
        display: flex;
        margin: auto;
        color: rgba(18, 31, 62, 0.8);
      }

      input {
        background: #f5f5f5;
        border: 1px solid #dfe3e6;
        border-radius: 4px;
        height: 35px;
        width: min(14vw, 100%);
        min-width: unset !important;
        padding-left: 16px;
        outline: none;
        font-weight: 400;
        font-size: 1.1vw;
        line-height: 16px;
        margin-bottom: 0 !important;
      }

      select {
        height: 35px;
        border: none;
        outline: none;
      }
    }

    .add-slot {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      button {
        width: 90px;
        height: 35px;
        border-radius: 4px;
        margin-top: 15px;
      }
    }
  }
}

/* ************************************************** media query **************************************************  */

@media (max-width: 63em) {
  .main-dashboard-div {
    .custome-slot {
      .input-container {
        .input {
          font-size: 14px;
          width: 100px;
          text-align: center;
          height: 35px;
          line-height: unset;
        }
        .input-wrapper {
          .input-inner-container {
            .md-wrapper {
              span {
                font-size: 18px;
              }
            }
            .btn-wrapper {
              width: 70px;
              .rm-btn {
                width: 64px;
                font-size: 12px;
              }
            }
          }
          .input-error {
            font-size: 12px;
            padding-left: 10px;
          }
        }
      }
    }
  }
}
