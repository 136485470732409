.text-speech {
  button {
    border: none;
    outline: none;
    background: transparent;
    padding: 10px;
    .paused {
      animation-play-state: paused;
    }
    .spinner {
      width: 24px;
      height: 24px;
    }
    img {
      width: 24px;
      height: 24px;
    }
    svg {
      width: 24px !important;
      height: 24px !important;
    }
    &:disabled {
      opacity: 0.8;
    }
  }
}
