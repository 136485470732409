.section-signup {
    // height: 1024px;
    .comman-container {
        .inner-container {
            height: 899px;
            .left-column {
                img {
                    position: relative;
                    z-index: -2;
                    height: 100%;
                }
            }
            .right-column {
                .container-wrapper {
                    .container-wrapper-inner {
                        width: 100%;
                    }
                }
            }
        }
    }
}